/* Required CSS */
.mar-top20{
  margin-top: 20px;
}
/* Required CSS */



.modal-header-custom {
  font-size: 13px !important;
  margin: 5px !important;
}
.font-size-13 {
  font-size: 13px !important;
}
.font-size-12 {
  font-size: 12px !important;
}
.font-size-10 {
  font-size: 10px !important;
}
.alert-padding {
  padding: 5px 10px 5px 10px !important;
}
.padding-top-bottom4{
  padding-top:4px !important;
  padding-bottom: 4px !important;
}
.padding-0 {
  padding: 0px !important;
}
.padding-4{
  padding:4px !important;
}
.padding-10 {
  padding:10px !important;
}
.padding-left0{
  padding-left: 0px !important;
}
.padding-bottom5{
  padding-bottom: 5px !important;
}
.padding-left21{
  padding-left: 21px !important;
}
.padding-left10{
  padding-left: 10px !important;
}
.margin-0{
  margin:0px !important;
}
.margin-15{
  margin:15px !important;
}
.mar-top-bottom-10 {
  margin: 10px 0px !important;
}
.mar-right10 {
  margin-right: 10px !important;
}
.mar-right15 {
  margin-right: 15px !important;
}
.mar-right17 {
  margin-right: 17px !important;
}
.mar-top5 {
  margin-top:5px !important;
}
.mar-top7 {
  margin-top:7px !important;
}
.mar-top10 {
  margin-top:10px !important;
}
.mar-top3{
  margin-top:3px !important;
}
.mar-bottom5 {
  margin-bottom:5px !important;
}
.mar-bottom20 {
  margin-bottom:20px !important;
}
.mar-top20 {
  margin-top:20px !important;
}
.mar-top25 {
  margin-top:25px !important;
}
.mar-top30 {
  margin-top:30px !important;
}
.mar-topM25 {
  margin-top:-25px !important;
}
.mar-leftm5{
  margin-left:-5px !important;
}
.mar-right5{
  margin-right:5px !important;
}
.font-weight-bold {
  font-weight: bold !important;
}
.font-weight-normal {
  font-weight: normal !important;
}

.btn {
  text-transform: uppercase;
}
.red {
  color: #f00;
}
.notification{
  font-weight: bold !important;
  font-size:12px !important;
  text-transform: capitalize;
}
a.btn, button.btn-link,button.btn {font-size: 10px !important; font-weight: bold; color: #000 !important; }
a.btn:hover, button.btn-link:hover { text-decoration: underline }
.btn:focus {
  outline: none !important;
  border: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.business-radio {
  margin:5px 0px 5px 20px !important;
}
.business-radio-text {
  margin-top: 3px !important;
}
.business-country-code {
  padding: 10px 20px !important;
}
.btn-warning {
  background-color: #FFDD00 !important;
  border-color: #FFDD00 !important;
}
.display-block-center {
  display:block;
  text-align: center;
}
.heading-header {
  color:  #FFDD00;
  width: 300px;
  margin: 10px auto 10px auto;
  text-align: center;
  font-size: 16px !important;
  font-weight: bold;
  text-transform: uppercase;
}
.logged-in-user {
  color:  #FFDD00;
  font-size: 14px !important;
  font-weight: bold;
  margin: 10px 5px 10px 0px;
}

.main-heading {
  color:  #000;
  /* width: 400px; */
  margin: 10px auto;
  text-align: center;
  font-size: 14px !important;
  text-transform: uppercase;
  font-weight: bold;
}
.maintenance-menu-box {
  box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.75) !important;
}
.card-header-custom {
  font-size: 14px !important;
  padding: 6px 10px !important;
  /* text-transform: uppercase; */
  text-align: center;
  font-weight: bold;
}
.card-body-custom {
  padding: 10px !important;
  font-size: 12px !important;
}

.grid-edit-btn {
  padding: 2px 10px 21px 10px !important;
  margin-top: -5px !important;
  height: 18px !important;
}
.pagination-dropdown {
  padding: 5px 0px;
}
.pagination-dropdown select {
  display: inline;
  margin-left: 5px;
  width: 80px;
}
.dashboard{
  width:100%;
  height: auto;
}


/* Header css */
.nav-logo {
  justify-content: flex-start;
  cursor:pointer;
}
.nav-env-name {
  position: absolute;
  width:auto;
  left:50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.nav-login-details {
  justify-content: flex-end;
  cursor:pointer;
}
/* Header css */


/* Tip Log */
.tip-log-search-container {
  width:750px;
  margin: 0px auto;
}
.account-log-search-container {
  width:600px;
  margin: 0px auto;
}
.padding-7{
  padding:7px !important;
}
.tlarTable tr th{ 
  width:200px !important;
}
/* Tip Log */

input[type="date"]:before {
  content: attr(placeholder) !important;
  margin-right: 0.5em;
}

input[type="date"].full:before {
  content: '' !important;
}

.bg-dark-custom {
  background-color: #000 !important;
}

.bg-yellow-custom {
  background-color: #FFDD00 !important;
}
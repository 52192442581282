.registration-content .field,
.registration-content .age-mobile,
.registration-content .buttons {
     margin-top: 10px;
}

.registration-content .container {
    margin: 20px;
}


.age-mobile .age {
    width: 45%;
}

.registration-content .buttons {
    margin: 20px 0px
}

.registration-content .buttons .button-primary {
    background: #FFDD00;
    color: black;
}

.registration-content .buttons .button-secondary {
    border: 1px solid black;
    color: black;
}

.registration-content .buttons button {
    width: 100%;
    border-radius: 20px;
}

.select-error {
    color: #d32f2f !important
}

.registration-card {
    margin: 20px
}

.card-content {
    position: relative;
}

.card-content-toast {
    position: absolute !important;
}
.lookup-content {
    overflow: auto;
    height: 80vh;
}

.lookup-content .search-card{
    height: auto;
}

.lookup-content .result-card {
    width: 100%;
}

.lookup-content .buttons button {
    width: 100%;
    border-radius: 20px;
}

.lookup-content  .buttons {
    margin: 20px 0;
}

.lookup-content .buttons .button-primary {
    background: #FFDD00;
    color: black;
}

.lookup-content .buttons .button-secondary {
    border: 1px solid black;
    color: black;
}

.result-card thead {
    background: lightgray;
}

.result-card td,
.result-card th{
    border-right: 0.5px solid grey;
}
.MuiFormHelperText-root.Mui-error {
    margin: 0;
    height:0;
}

#tooltip-email.bs-tooltip-bottom .arrow::before {
    border-bottom-color: lightgrey !important;
  }
  
  #tooltip-email .tooltip-inner {
    background-color: lightgrey !important;
    padding: 15px;
    color: #000
  }
  
.buttons .button-primary {
    background: #FFDD00;
    color: black;
}

.buttons .button-primary:hover {
    background: #FFDD00;
    color: black;
}

.buttons .button-secondary {
    border: 1px solid black;
    color: black;
}

.buttons .button-secondary:hover {
    border: 1px solid black;
    color: black;
}

.buttons button {
    width: 30%;
    border-radius: 20px;
}

.age-mobile .age {
    width: 100%;
}

.age-mobile {
     margin-top: 10px;
}

.language-preference .language {
    width: 20%;
}

.language-preference {
     margin-top: 10px;
}

.registration-card {
    margin: 20px
}
